<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Lesson planner</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \ Lesson planner
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn @click="__add()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add lesson plan
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="4">
                    <v-text-field dense label="Title"
                                  outlined
                                  type="text" clearable
                                  v-model="search.title"/>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="programs"
                              @change="getGrades(search.program_id)" dense
                              item-text="title"
                              item-value="id"
                              label="Program"
                              outlined
                              v-model="search.program_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="academicYears" dense item-text="title" item-value="id"
                              label="Batch/year"
                              outlined
                              v-model="search.academic_year_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="gradesLevels" @change="changeLevel" dense item-text="title"
                              item-value="id"
                              label="Grade/semester"
                              outlined
                              v-model="search.grade_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="courses" dense item-text="title" item-value="id"
                              label="Subjects"
                              outlined
                              v-model="search.course_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="academic_classes" dense item-text="title" item-value="id"
                              label="Class"
                              outlined
                              v-model="search.class_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="users" dense item-text="full_name" item-value="id"
                              label="Teachers"
                              outlined
                              v-model="search.teacher_id"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select :items="active_statuses" dense item-text="title" item-value="value"
                              label="Status"
                              outlined
                              v-model="search.active"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn
                        @click="__get()"
                        class="btn btn-primary w-35 float-right"
                        :loading="loading"
                        dark
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>

                </div>
              </div>
              <div class="">
                <table class="table">
                <thead>
                  <tr class="px-3">
                  <th class="px-3 wrap-column">Title</th>
                  <th class="px-3 wrap-column">Current lesson/topic</th>
                  <th class="px-3 wrap-column">Class/course</th>
                  <th class="px-3 wrap-column">Info</th>
                  <th class="px-1">Action</th>
                </tr>
                </thead>
                  <template>
                    <draggable @change="sort" class="text-left" handle=".handle" tag="tbody"
                           v-if="lesson_planners.length > 0" v-model="lesson_planners">
                          <tr v-for="(lesson_plan, index) of lesson_planners" :key="index">
                            <td class="px-3 wrap-column">
                              <a @click="courseSummary(lesson_plan)"
                                class="text-primary font-weight-bolder text-hover-primary mb-1">
                                {{lesson_plan.title}}
                              </a>&nbsp;&nbsp; <i :class="lesson_plan.is_active?'dot-active':'dot-inactive'"
                                class="fas fa-circle"></i>
                              <br>
        <!--                      <strong>
                                Instructor :
                              </strong>-->
                              <div>
                              <span   class="font-weight-regular subtitle-1" v-if="lesson_plan.teacher_id">
                                <b>Instructor: </b> {{ `${lesson_plan.teacher.first_name} ${lesson_plan.teacher.middle_name || ''} ${lesson_plan.teacher.last_name}` }}
                              </span>
                              <span class="font-weight-regular subtitle-1" v-else>
                              <b>Instructor:</b>   N/A
                              </span>
                              </div>

                            </td>
                            <td class="px-3 wrap-column">
                              <span class="font-weight-regular" v-if="lesson_plan.current_topic_lesson">
                                {{lesson_plan.current_topic_lesson.title || 'N/A'}}
                              </span>
                              <span class="font-weight-regular" v-else>
                                N/A
                              </span>
                            </td>

                            <td class="px-3 wrap-column" v-if="lesson_plan.course_id">
                              <div class="text-secondary">
                                <span class="font-weight-medium"> Course</span> : {{ lesson_plan.course.title }}
                              </div>
                              <div class="text-secondary" v-if="lesson_plan.academic_class_title">
                                <span class="font-weight-medium"> Class </span> : {{lesson_plan.academic_class_title || 'N/A'}}
                              </div>
                              <div class="text-secondary" v-if="lesson_plan.course && lesson_plan.course.grade.title">
                                <span class="font-weight-medium"> Grade </span> :  {{ lesson_plan.course.grade.title }}
                              </div>
                              <div class="text-secondary" v-if="lesson_plan.program && lesson_plan.program.grade.title">
                                <span class="font-weight-medium">Program </span> :  {{ lesson_plan.program.grade.title }}
                              </div>
                              <div class="text-secondary" v-if="lesson_plan.academic_year_id">
                                <span class="font-weight-medium">Academic year </span>:   {{ lesson_plan.academic_year.title }}
                              </div>
                            </td>


                            <td class="px-3 wrap-column">
                              <div class="text-secondary">
                                <b>Added by:</b> <br/>{{ lesson_plan.added_by_name ? lesson_plan.added_by_name.full_name : 'N/A'}}
                              </div>
                              <div class="text-secondary">
                                <b>Added date:</b> <br/>{{ lesson_plan.created_at}}
                              </div>


                            </td>

                            <td class="px-3">
                              <b-dropdown
                                  no-caret
                                  no-flip
                                  right
                                  size="sm"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  variant="link"
                              >
                                <template v-slot:button-content>
                                  <slot>
                                                                <span>
                                                                            <i class="flaticon-more-1"></i>
                                                                          </span></slot>
                                </template>
                                <!--begin::Navigation-->
                                <div class="navi navi-hover ">

                                  <b-dropdown-text class="navi-item" tag="div">
                                    <router-link :to="{name:'course-summary',
                                                                                params:{
                                                                                    lesson_planner_id:lesson_plan.id
                                                                                }}" class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-chalkboard-teacher"></i>
                                                                    </span>
                                      <span class="navi-text">Lesson planner</span>
                                    </router-link>
                                  </b-dropdown-text>
                                  <b-dropdown-text class="navi-item" tag="div">
                                    <a @click="__cloneLessonPlanner(lesson_plan.id)"
                                      class="navi-link"
                                      href="#">
                                                                    <span class="navi-icon">
                                                                      <i class="flaticon2-copy"></i>
                                                                    </span>
                                      <span class="navi-text"> Clone </span>
                                    </a>
                                  </b-dropdown-text>
                                  <b-dropdown-text class="navi-item" tag="div">
                                    <a @click="__edit(lesson_plan.id)" class="navi-link" href="#">
                                                                    <span class="navi-icon">
                                                                      <i class="flaticon-edit"></i>
                                                                    </span>
                                      <span class="navi-text"> Edit </span>
                                    </a>
                                  </b-dropdown-text>

                                  <b-dropdown-text class="navi-item" tag="div">
                                    <a @click="__delete(lesson_plan.id)" class="navi-link"
                                      href="#">
                                                                    <span class="navi-icon">
                                                                      <i class="fas fa-trash"></i>
                                                                    </span>
                                      <span class="navi-text">  Delete</span>
                                    </a>
                                  </b-dropdown-text>

                                </div>
                                <!--end::Navigation-->
                              </b-dropdown>

                            </td>
                          </tr>
                    </draggable>
                    <tr v-if="lesson_planners.length == 0">
                      <td colspan="5" class="text-center">Data not available</td>
                    </tr>
                  </template>

                </table>
                <b-pagination
                    v-if="lesson_planners.length > 0"
                    class="pull-right mt-7"
                    :per-page="perPage"
                    :total-rows="total"
                    @input="__get"
                    first-number
                    last-number
                    v-model="page"
                ></b-pagination>
              </div>

            </div>
            <create-and-update @refresh_lesson_plan="__get" ref="lesson_plan"></create-and-update>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>

    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";
    import FilterMixin from "@/mixins/FilterMixin";
    import CreateAndUpdate from "./CreateAndUpdate";
    import draggable from 'vuedraggable'
    import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";

    const lessonPlannerService = new LessonPlannerService();

    export default {
        name: "lesson-planner",
        mixins: [LessonPlannerMixin, FilterMixin],
        display: "Table",
        order: 8,
        components: {
            CreateAndUpdate,
            draggable
        },
        data() {
            return {
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'Inactive', value: "in_active"}
                ],
                lesson_planners: [],
                page: null,
                perPage: null,
                total: null,
                dragging: false,
                loading: false
            };
        },
        mounted() {
            this.__get();
            this.getAcademicYears();
            this.getAllPrograms();
            this.getUsers(
                {
                    type:'teacher'
                }
            );

        },
        methods: {
            courseSummary(lesson_plan){
            this.$router.push({
              name: 'course-summary',
              params: {lesson_plan_id:lesson_plan.id}
            })
          },
            __get() {
                this.loading=true;
                lessonPlannerService
                    .paginate(this.search)
                    .then(response => {
                        this.lesson_planners = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                        this.loading=false;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            __add() {
                this.$refs["lesson_plan"].showModal();
            },
            __edit(id) {
                this.$refs["lesson_plan"].showModal(id);
            },
            changeLevel(){
              this.getCourses(this.search);
              this.getAcademicClasses(this.search.academic_year_id,this.search.program_id,this.search.grade_id);
            },

            __delete(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            lessonPlannerService
                                .delete(id)
                                .then(response => {
                                    this.__get();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            __cloneLessonPlanner(id) {
                this.$confirm({
                    message: `Confirm clone lesson plan?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            lessonPlannerService
                                .clone(id)
                                .then(response => {
                                    this.__get();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            sort() {
                lessonPlannerService.sort(this.lesson_planners).then((res) => {
                    this.$snotify.success("Sorted");
                    this.__get()
                }).catch(err => {
                    this.$snotify.success("Sorted Error");
                })
            },

        }
    };
</script>
<style scoped>
    .buttons {
        margin-top: 35px;
    }
</style>
