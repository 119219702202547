import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class LessonsService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/topic`;
      }
      paginate(syllabusId,data = null, index=null) {
            let url = `${this.#api}/${syllabusId}/lesson`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index != null)
                  url = url + '?' + "page=" + (index);
            return apiService.get(url);
      }

      update(syllabusId,id, data) {
            let url = `${this.#api}/${syllabusId}/lesson/${id}`
            return apiService.put(url, data);
      }

      store(syllabusId,data) {
            let url = `${this.#api}/${syllabusId}/lesson`
            return apiService.post(url, data);
      }

      show(syllabusId,id) {
            let url = `${this.#api}/${syllabusId}/lesson/${id}`
            return apiService.get(url);
      }
      getSingle(id) {
            let url = `${this.#api}/${id}/get`
            return apiService.get(url);
      }
      markComplete(id) {
            let url = `${this.#api}/${id}/complete`
            return apiService.get(url);
      }
      markInComplete(id) {
            let url = `${this.#api}/${id}/incomplete`
            return apiService.get(url);
      }
      sort( syllabusId,data) {
            let url = `${this.#api}/${syllabusId}/lesson/sort-order`;
            return apiService.post(url, data);
      }


      delete(syllabusId,id) {
            let url = `${this.#api}/${syllabusId}/lesson/${id}`
            return apiService.delete(url);
      }
}
